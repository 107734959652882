<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                  <li class="breadcrumb-item active">Asignar Vehículos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" v-if="oferta">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="title">Asignación de Vehículos</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-center">
                  <div class="col-md-7">
                    <div class="card m-0">
                      <div class="card-header bg-frontera text-white">
                        <h3 class="card-title">
                          Detalle Subasta - {{ oferta.subasta.nombre }}
                          <small
                            >({{ oferta.subasta.fecha_ini }} -
                            {{ oferta.subasta.fecha_fin }})</small
                          >
                        </h3>
                      </div>
                      <div class="card-body text-muted text-xs">
                        <div class="row justify-content-center">
                          <div class="col-md-5">
                            <ul class="ml-4 mb-0 fa-ul">
                              <li>
                                <span class="fa-li">
                                  <i class="fas fa-lg fa-info"></i>
                                </span>
                                <strong>Oferta</strong>
                                <div class="row">
                                  <div class="col-md-5">Cliente:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      oferta.cliente.razon_social
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Precio Oferta:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong
                                      >{{ oferta.precio_oferta }}
                                      <small
                                        >({{
                                          oferta.subasta_producto.nMoneda
                                        }})</small
                                      ></strong
                                    >
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Estado:</div>
                                  <div class="col-md-7 mb-1">
                                    <span class="badge bg-lightblue">
                                      {{ oferta.nEstado }}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-5">
                            <ul class="ml-4 mb-0 fa-ul">
                              <li>
                                <span class="fa-li">
                                  <i class="fas fa-lg fa-cogs"></i>
                                </span>
                                <strong
                                  >Producto -
                                  {{
                                    oferta.subasta_producto.producto.nombre
                                  }}</strong
                                >
                                <div class="row">
                                  <div class="col-md-5">Sitio:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      oferta.subasta_producto.sitio.nombre
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">API:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      oferta.subasta_producto.api
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Azufre:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      oferta.subasta_producto.s
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">Volumen:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      oferta.subasta_producto.volumen
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5">
                                    Cantidad Vehículos:
                                  </div>
                                  <div class="col-md-7 mb-1">
                                    <strong>{{
                                      oferta.subasta_producto.cant_vh
                                    }}</strong>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="card m-0">
                      <div class="card-header bg-frontera text-white">
                        <h3 class="card-title">Liquidación</h3>
                      </div>
                      <div class="card-body text-muted text-xs">
                        <div class="row">
                          <div class="form-group col-md-6 text-center">
                            <b>Saldo Inicial: </b><br />
                            {{ this.oferta.cliente.saldos }}
                          </div>
                          <div class="form-group col-md-6 text-center">
                            <b>Anticipos: </b><br />
                            {{ this.oferta.cliente.anticipos }}
                          </div>
                          <div class="form-group col-md-4 text-center">
                            <b>Saldo Inicial + Anticipos </b><br />
                            {{ this.oferta.cliente.saldoMasAnti }}
                          </div>
                          <div class="form-group col-md-4 text-center">
                            <b>Entregas: </b><br />
                            {{ this.oferta.cliente.entregas }}
                          </div>
                          <div class="form-group col-md-4 text-center">
                            <b>Saldo Final: </b> <br />
                            {{ this.oferta.cliente.saldo_final }}
                          </div>
                          <div class="col-md-12">
                            <div class="progress">
                              <div
                                class="progress-bar progress-bar-striped"
                                :class="{
                                  'bg-danger':
                                    this.oferta.cliente.porcentaje > 80,
                                  'bg-warning':
                                    this.oferta.cliente.porcentaje > 60 &&
                                    this.oferta.cliente.porcentaje <= 80,
                                  'bg-success':
                                    this.oferta.cliente.porcentaje <= 60,
                                }"
                                :style="
                                  'width:' +
                                    this.oferta.cliente.porcentaje +
                                    '%'
                                "
                              >
                                <b>{{ this.oferta.cliente.porcentaje }}%</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div v-for="det in oferta.detalles" :key="det.id">
                  <div
                    class="card card-outline card-info collapsed-card mb-2"
                    v-if="
                      det.fecha <= manana &&
                        det.cant_aceptada != null &&
                        det.cant_aceptada > 0
                    "
                  >
                    <div class="card-header pt-2 pb-2">
                      <h3 class="card-title"></h3>
                      <div class="row">
                        <div class="col-md-4">
                          <span
                            class="badge mr-2"
                            :class="
                              det.postulaciones.length == det.cant_aceptada
                                ? 'bg-success'
                                : det.postulaciones.length > 0
                                ? 'bg-warning'
                                : 'bg-danger'
                            "
                          >
                            {{ det.postulaciones.length }}/{{
                              det.cant_aceptada
                            }}
                          </span>
                        </div>
                        <div class="col-md-4">
                          <b>{{ det.fecha }}</b>
                        </div>
                        <div class="col-md-4">
                          <span
                            class="badge mr-2"
                            :class="
                              det.postulaciones.length == det.cant_aceptada
                                ? 'bg-success'
                                : det.postulaciones.length > 0
                                ? 'bg-warning'
                                : 'bg-danger'
                            "
                          >
                            {{
                              det.postulaciones.length == det.cant_aceptada
                                ? "Postulado"
                                : det.postulaciones.length > 0
                                ? "Faltan vehículos por postular"
                                : "No hay vehículos postulados"
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="card-tools">
                        <button
                          type="button"
                          data-card-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                          class="btn btn-tool"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      class="card-body table_responsive p-0"
                      style="display: none"
                    >
                      <div
                        class="row justify-content-center"
                        v-if="
                          $store.getters.can('com.postulaciones.create') &&
                            det.postulaciones.length != det.cant_aceptada &&
                            det_oferta != det.id &&
                            hoy <= det.fecha &&
                            accion == 1
                        "
                      >
                        <div class="col-md-12 text-right m-2 pr-4">
                          <div class="btn-group">
                            <button
                              class="btn bg-info btn-sm"
                              @click="createForm(det, oferta)"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="table-responsive"
                        v-if="
                          det.postulaciones.length > 0 || det_oferta == det.id
                        "
                      >
                        <table
                          class="table table-bordered table-striped table-hover table-sm text-nowrap"
                        >
                          <thead>
                            <tr class="text-center" style="font-size: 11px">
                              <th style="min-width: 90px">Vehículo</th>
                              <th style="min-width: 90px">Remolque</th>
                              <th style="width: 100px">Numero Ejes</th>
                              <th style="min-width: 100px">Peso Vacio</th>
                              <th style="min-width: 200px">Transportadora</th>
                              <th style="min-width: 300px">
                                <div class="row">
                                  <div style="min-width: 100px">Cedula</div>
                                  <div style="min-width: 200px">Conductor</div>
                                </div>
                              </th>
                              <th style="min-width: 250px">Sitio Destino</th>
                              <th style="min-width: 80px">Hora Cargue</th>
                              <th style="min-width: 200px">
                                <div class="row">
                                  <div style="min-width: 80px">
                                    Numero orden
                                  </div>
                                  <div style="min-width: 120px">link</div>
                                </div>
                              </th>
                              <th style="min-width: 90px">Estado</th>
                              <th
                                style="min-width: 90px"
                                v-if="hoy <= det.fecha"
                              >
                                Acción
                              </th>
                            </tr>
                          </thead>
                          <tbody v-for="pos in det.postulaciones" :key="pos.id">
                            <tr v-if="postu != pos.id">
                              <td class="text-center">
                                <i
                                  class="fa mr-1"
                                  :class="
                                    pos.vehiculo_id
                                      ? 'fa-check text-success'
                                      : 'fa fa-times-circle text-danger'
                                  "
                                ></i>
                                {{ pos.vehiculo }}
                              </td>
                              <td class="text-center">
                                <i
                                  class="fa mr-1"
                                  :class="
                                    pos.remolque_id
                                      ? 'fa-check text-success'
                                      : 'fa fa-times-circle text-danger'
                                  "
                                ></i>
                                <span>{{ pos.remolque }}</span>
                              </td>
                              <td class="text-center">
                                <i class="fa mr-1"></i>
                                {{ pos.numero_ejes }}
                              </td>
                              <td class="text-center">
                                <i class="fa mr-1"></i>
                                {{ pos.peso_vacio }}
                              </td>
                              <td class="text-center">
                                <i class="fa mr-1"></i>
                                {{
                                  pos.transportadora
                                    ? pos.transportadora.razon_social
                                    : ""
                                }}
                              </td>
                              <td>
                                <i
                                  class="fa mr-1"
                                  :class="
                                    pos.conductor_id
                                      ? 'fa-check text-success'
                                      : 'fa fa-times-circle text-danger'
                                  "
                                ></i>
                                <span>{{ pos.nom_conductor }}</span>
                                <span class="badge bg-info float-left">
                                  {{ pos.doc_conductor }}
                                </span>
                                <br />
                                <span>
                                  {{ pos.cel_cond }}
                                </span>
                              </td>
                              <td class="text-center">
                                <div class="row">
                                  <div class="col-md-12">
                                    {{ pos.sitio_destino }}
                                  </div>
                                  <div v-show="pos.nDireccion">
                                    Direccion:
                                    {{
                                      pos.nDireccion?.includes(",")
                                        ? pos.nDireccion.split(",")[1].trim()
                                        : ""
                                    }}
                                  </div>
                                </div>
                              </td>
                              <td class="text-center">
                                {{ pos.fecha_cargue }}
                              </td>
                              <td>
                                <span>{{ pos.numero_orden }}</span>
                                <button
                                  v-if="
                                    pos.numero_orden !== '' &&
                                      pos.numero_orden !== null &&
                                      typeof pos.numero_orden !== 'undefined'
                                  "
                                  type="button"
                                  class="btn btn-sm bg-gray-dark float-right mr-4"
                                  @click="getImage(pos.link_orden)"
                                >
                                  <i class="fas fa-image"></i>
                                </button>
                              </td>

                              <td class="text-center">
                                <span
                                  class="badge"
                                  :class="
                                    pos.nEstado == 'Postulado'
                                      ? 'bg-lightblue'
                                      : 'bg-success'
                                  "
                                >
                                  {{ pos.nEstado }}
                                </span>
                              </td>
                              <td class="text-right">
                                <div class="btn-group">
                                  <button
                                    class="btn bg-navy btn-sm"
                                    v-if="
                                      $store.getters.can(
                                        'com.postulaciones.edit'
                                      ) &&
                                        accion == 1 &&
                                        (!pos.det_guia_regalia_tercero_id ||
                                          (pos.det_guia_regalia_tercero_id &&
                                            pos.det_guia_regalia_tercero
                                              .guia_id == null))
                                    "
                                    @click="editForm(pos)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <button
                                    class="btn btn-danger btn-sm"
                                    v-if="
                                      $store.getters.can(
                                        'com.postulaciones.delete'
                                      ) &&
                                        accion == 1 &&
                                        (!pos.det_guia_regalia_tercero_id ||
                                          (pos.det_guia_regalia_tercero_id &&
                                            pos.det_guia_regalia_tercero
                                              .guia_id == null))
                                    "
                                    @click="destroy(pos.id)"
                                  >
                                    <i class="fas fa-trash"></i>
                                  </button>
                                  <button
                                    class="btn bg-navy btn-sm"
                                    v-if="
                                      $store.getters.can(
                                        'com.postulaciones.cambioFecha'
                                      ) &&
                                        accion == 1 &&
                                        (!pos.det_guia_regalia_tercero_id ||
                                          (pos.det_guia_regalia_tercero_id &&
                                            pos.det_guia_regalia_tercero
                                              .guia_id == null))
                                    "
                                    data-toggle="modal"
                                    data-target="#modal-cambio-fecha"
                                    @click="abrirModalFechas(pos)"
                                  >
                                    <i class="fas fa-calendar-alt"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td class="p-1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form.vehiculo"
                                  :class="
                                    $v.form.vehiculo.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @change="buscarVehiculos(det.postulaciones)"
                                />
                              </td>
                              <td class="p-1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form.remolque"
                                  :class="
                                    $v.form.remolque.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @change="buscarRemolques(det.postulaciones)"
                                />
                              </td>
                              <td>
                                <div>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="form.numero_ejes"
                                    :class="
                                      $v.form.numero_ejes.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    @change="valEjes()"
                                    maxlength="2"
                                  />
                                </div>
                              </td>
                              <td>
                                <div>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="form.peso_vacio"
                                    :class="
                                      $v.form.peso_vacio.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    @change="valPesoVacio()"
                                  />
                                </div>
                              </td>
                              <td class="p-1">
                                <v-select
                                  :class="[
                                    $v.form.transportadora_id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  v-model="transportadora"
                                  placeholder="Transportadora"
                                  label="razon_social"
                                  class="form-control form-control-sm p-0 zIndex: '9999'"
                                  menu-props="{ position: 'absolute' , zIndex: '9999'}"
                                  @search="getEmpresas"
                                  @input="selectempresa(det.postulaciones)"
                                  :options="listasForms.transportadoras"
                                >
                                </v-select>
                              </td>
                              <td class="p-1">
                                <div class="row">
                                  <div class="col-md-5 pr-1">
                                    <input
                                      type="number"
                                      class="form-control form-control-sm"
                                      v-model="form.doc_conductor"
                                      placeholder="N°"
                                      :class="
                                        $v.form.doc_conductor.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      @change="
                                        buscarConductores(det.postulaciones)
                                      "
                                    />
                                  </div>
                                  <div class="col-md-7 pl-0">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="form.nom_conductor"
                                      placeholder="Nombre y Apellidos"
                                      :class="
                                        $v.form.nom_conductor.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      :disabled="form.conductor_id"
                                    />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5 mx-auto pr-1 mt-2">
                                    <input
                                      type="number"
                                      class="form-control form-control-sm"
                                      v-model="form.cel_cond"
                                      placeholder="Celular"
                                      :class="
                                        $v.form.cel_cond.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      :disabled="form.cel_cond"
                                      maxlength="10"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td class="p-1">
                                <v-select
                                  :class="[
                                    $v.form.sitio_destino.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  placeholder="nombre"
                                  label="nombre"
                                  v-model="sitios_destino"
                                  @input="selectDestino()"
                                  class="form-control form-control-sm p-0"
                                  :options="listasForms.destinosCliente"
                                  style="font-size: 10px"
                                >
                                </v-select>
                                <label for="direccion">{{
                                  sitios_destino.descripcion?.includes(",")
                                    ? sitios_destino.descripcion
                                        .split(",")[1]
                                        .trim()
                                    : ""
                                }}</label>
                              </td>

                              <td class="p-1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form.hora_cargue"
                                  :class="
                                    $v.form.hora_cargue.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  v-mask="{
                                    mask: '99:99',
                                    hourFormat: '24',
                                  }"
                                />
                              </td>
                              <td>
                                <div class="row">
                                  <div class="col-md-6 pr-1">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="form.numero_orden"
                                      placeholder="N. Orden"
                                      :class="
                                        $v.form.numero_orden.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      style="font-size: 10px"
                                    />
                                  </div>
                                  <div class="col-md-6 pl-1">
                                    <div
                                      v-if="
                                        form.link_orden === null ||
                                          form.link_orden
                                      "
                                    >
                                      <div v-if="form.link_orden">
                                        <button
                                          type="button"
                                          class="btn btn-sm bg-warning"
                                          @click="eliminarFoto()"
                                        >
                                          <i class="fas fa-trash-alt"></i>
                                        </button>
                                      </div>
                                      <div v-else>
                                        <div
                                          v-if="
                                            form.link_orden === null &&
                                              file === null
                                          "
                                        >
                                          <input
                                            type="file"
                                            id="file"
                                            ref="file"
                                            class="form-control-file"
                                            accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                                            @change="elegirDirectorio()"
                                          />
                                        </div>
                                        <div v-else>
                                          <div class="btn-group float-right">
                                            <button
                                              type="button"
                                              class="btn btn-sm bg-warning"
                                              @click="borrarFotoParcial()"
                                            >
                                              <i class="fas fa-trash-alt"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td class="p-1"></td>
                              <td class="text-right" v-if="hoy <= det.fecha">
                                <div class="btn-group">
                                  <button
                                    class="btn bg-info btn-sm"
                                    v-if="
                                      !$v.form.$invalid &&
                                        $store.getters.can(
                                          'com.postulaciones.edit'
                                        ) &&
                                        file != null
                                    "
                                    @click="save"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <button
                                    class="btn btn-danger btn-sm"
                                    @click="cancelForm()"
                                  >
                                    <i class="fas fa-ban"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-if="det_oferta == det.id">
                            <tr>
                              <td class="p-1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form.vehiculo"
                                  :class="
                                    $v.form.vehiculo.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @change="buscarVehiculos(det.postulaciones)"
                                  style="font-size: 10px"
                                />
                              </td>
                              <td class="p-1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form.remolque"
                                  :class="
                                    $v.form.remolque.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  @change="buscarRemolques(det.postulaciones)"
                                  style="font-size: 10px"
                                />
                              </td>
                              <td>
                                <div>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="form.numero_ejes"
                                    :class="
                                      $v.form.numero_ejes.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    style="font-size: 10px"
                                    @change="valEjes()"
                                    maxlength="2"
                                  />
                                </div>
                              </td>
                              <td>
                                <div>
                                  <input
                                    type="number"
                                    class="form-control form-control-sm"
                                    v-model="form.peso_vacio"
                                    :class="
                                      $v.form.peso_vacio.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    style="font-size: 10px"
                                    @change="valPesoVacio()"
                                  />
                                </div>
                              </td>
                              <td class="p-1">
                                <v-select
                                  :class="[
                                    $v.form.transportadora_id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  v-model="transportadora"
                                  placeholder="Transportadora"
                                  label="razon_social"
                                  class="form-control form-control-sm p-0"
                                  @search="getEmpresas"
                                  @input="selectempresa()"
                                  :options="listasForms.transportadoras"
                                  style="font-size: 10px"
                                >
                                </v-select>
                              </td>
                              <td class="p-1">
                                <div class="row">
                                  <div class="col-md-5 pr-1">
                                    <input
                                      type="number"
                                      class="form-control form-control-sm"
                                      placeholder="N°"
                                      v-model="form.doc_conductor"
                                      :class="
                                        $v.form.doc_conductor.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      @change="
                                        buscarConductores(det.postulaciones)
                                      "
                                    />
                                  </div>
                                  <div class="col-md-7 pl-0">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="form.nom_conductor"
                                      placeholder="Nombre y Apellidos"
                                      :class="
                                        $v.form.nom_conductor.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      :disabled="form.conductor_id"
                                    />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6 mx-auto pr-1 mt-2">
                                    <input
                                      type="number"
                                      class="form-control form-control-sm"
                                      v-model="form.cel_cond"
                                      placeholder="Celular"
                                      :class="
                                        $v.form.cel_cond.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      maxlength="10"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td class="p-1">
                                <v-select
                                  :class="[
                                    $v.form.sitio_destino.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid',
                                    $store.getters.getDarkMode
                                      ? 'dark-vselect'
                                      : '',
                                  ]"
                                  placeholder="nombre"
                                  label="nombre"
                                  v-model="sitios_destino"
                                  @input="selectDestino()"
                                  class="form-control form-control-sm p-0"
                                  :options="listasForms.destinosCliente"
                                  style="font-size: 10px"
                                >
                                </v-select>
                                <label for="direccion">
                                  <small>
                                    Dirección:
                                    {{
                                      sitios_destino &&
                                      sitios_destino.descripcion
                                        ? sitios_destino.descripcion.includes(
                                            ","
                                          )
                                          ? sitios_destino.descripcion
                                              .split(",")[1]
                                              .trim()
                                          : ""
                                        : ""
                                    }}
                                  </small>
                                </label>
                              </td>
                              <td class="p-1">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  v-model="form.hora_cargue"
                                  :class="
                                    $v.form.hora_cargue.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                  v-mask="{
                                    mask: '99:99',
                                    hourFormat: '24',
                                  }"
                                  style="font-size: 10px"
                                />
                              </td>
                              <td>
                                <div class="row">
                                  <div class="col-md-6 pr-1">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="form.numero_orden"
                                      :class="
                                        $v.form.numero_orden.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                      placeholder="N. Orden"
                                      style="font-size: 10px"
                                    />
                                  </div>
                                  <div class="col-md-6 pl-1">
                                    <div v-if="form.link_orden">
                                      <button
                                        type="button"
                                        class="btn btn-sm bg-warning"
                                        @click="eliminarFoto()"
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </div>
                                    <div v-else>
                                      <div
                                        v-if="
                                          form.link_orden === null &&
                                            file === null
                                        "
                                      >
                                        <input
                                          type="file"
                                          id="file"
                                          ref="file"
                                          class="form-control-file"
                                          accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                                          @change="elegirDirectorio()"
                                        />
                                      </div>
                                      <div v-else>
                                        <div class="btn-group float-right">
                                          <button
                                            type="button"
                                            class="btn btn-sm bg-warning"
                                            @click="borrarFotoParcial()"
                                          >
                                            <i class="fas fa-trash-alt"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td></td>
                              <td>
                                <div class="btn-group">
                                  <button
                                    class="btn bg-navy btn-sm"
                                    v-if="
                                      !$v.form.$invalid &&
                                        $store.getters.can(
                                          'com.postulaciones.create'
                                        ) &&
                                        file != null
                                    "
                                    @click="save"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                  <button
                                    class="btn btn-danger btn-sm"
                                    @click="cancelForm()"
                                  >
                                    <i class="fas fa-ban"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else class="row justify-content-center">
                        <div class="col-md-6">
                          <div class="callout callout-warning m-3">
                            <h5>
                              No se han postulado vehículos para la fecha
                              {{ det.fecha }}!
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PostulacionCambioFecha ref="PostulacionCambioFecha" />
  </div>
</template>

<script>
import axios from "axios";
import PostulacionCambioFecha from "./PostulacionCambioFecha.vue";
import Loading from "../../../../components/Loading";
import {
  required,
  numeric,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import moment from "moment";
import vSelect from "vue-select";
import "moment/locale/es";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "ComPostulacionForm",
  components: {
    vSelect,
    PostulacionCambioFecha,
    Loading,
  },

  data() {
    return {
      cargando: false,
      oferta: null,
      transportadora: null,
      det_oferta: null,
      /* falgValidando: false, */
      sitios_destino: null,
      file: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      postu: null,
      form: {},
      hoy: moment().format("YYYY-MM-DD"),
      manana: moment()
        .add(5, "days")
        .format("YYYY-MM-DD"),
      accion: 0,
      saldoFinal: 0,
      listasForms: {
        transportadoras: [],
        destinosCliente: [],
      },
    };
  },

  validations: {
    form: {
      com_oferta_subasta_det_id: {
        required,
      },
      vehiculo: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
      remolque: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
      numero_ejes: {
        required,
        numeric,
      },
      numero_orden: {
        required,
      },
      peso_vacio: {
        required,
      },
      transportadora_id: {
        required,
      },
      doc_conductor: {
        required,
      },
      nom_conductor: {
        required,
      },
      sitio_destino: {
        required,
      },
      hora_cargue: {
        required,
        timeFormat,
      },
      cel_cond: {
        required,
      },
    },
  },

  methods: {
    borrarFotoParcial() {
      this.file = null;
      this.form.link_orden = null;
    },

    getIndex() {
      this.cargando = true;
      const me = this;
      this.accion = this.$route.params.accion;

      axios
        .get("/api/com/postulaciones/" + this.$route.params.oferta_id)
        .then((response) => {
          this.oferta = response.data;
          this.calcSaldos();
          this.listasClientes();
          // Se ajusta los datos del conductor
          this.oferta.detalles.forEach((of) => {
            of.postulaciones.forEach((p) => {
              let array_conductor = p.conductor.split(" ");
              p.doc_conductor = array_conductor[0];
              array_conductor.shift();
              p.nom_conductor = array_conductor.join(" ");
            });
          });
          this.cargando = false;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    /***** Funciones para los campos de los formularios */
    resetForm() {
      this.transportadora = null;
      this.sitios_destino = null;
      this.form = {
        id: null,
        com_oferta_subasta_det_id: null,
        vehiculo_id: null,
        vehiculo: null,
        transportadora_id: null,
        remolque_id: null,
        remolque: null,
        numero_ejes: null,
        peso_vacio: null,
        conductor_id: null,
        conductor: null,
        link_orden: null,
        numero_orden: null,
        doc_conductor: null,
        nom_conductor: null,
        sitio_destino: null,
        destino_tercero: null,
        fecha_cargue: null,
        cel_cond: null,
        estado: 1,
      };
    },

    createForm(det, oferta) {
      let volum =
        parseFloat(oferta.subasta_producto.volumen) /
        parseFloat(oferta.subasta_producto.cant_vh);

      let volVh = parseFloat(volum) * parseFloat(det.cant_aceptada);
      //let brent = oferta.guias[oferta.guias.length - 1].brent;

      let brent = 0;
      if (oferta.guias) {
        brent = 77.62;
      } else {
        brent = oferta.guias[oferta.guias.length - 1].brent;
      }

      let tot = parseFloat(volVh) * parseFloat(brent);

      if (tot > this.saldoFinal) {
        this.$swal({
          icon: "error",
          title: "No se puede postular ya que no cuenta con saldo ",
          showConfirmButton: true,
        });
        return false;
      }
      this.cancelForm();
      this.transportadora = null;
      this.sitios_destino = null;
      this.det_oferta = det.id;
      this.file = null;
      this.form = {
        id: null,
        com_oferta_subasta_det_id: det.id,
        vehiculo_id: null,
        vehiculo: null,
        remolque_id: null,
        remolque: null,
        numero_ejes: null,
        peso_vacio: null,
        transportadora_id: null,
        conductor_id: null,
        conductor: null,
        numero_orden: null,
        doc_conductor: null,
        nom_conductor: null,
        link_orden: null,
        cel_cond: null,
        sitio_destino: null,
        destino_tercero: null,
        fecha_cargue: null,
        fecha: det.fecha,
        hora_cargue: null,
        estado: 1,
      };
    },

    async editForm(pos) {
      this.cancelForm();
      this.postu = pos.id;
      this.file;
      this.transportadora = pos.transportadora;
      this.sitios_destino = pos.sitio_destino;
      this.form = {
        id: pos.id,
        com_oferta_subasta_det_id: pos.com_oferta_subasta_det_id,
        vehiculo_id: pos.vehiculo_id,
        vehiculo: pos.vehiculo,
        remolque_id: pos.remolque_id,
        remolque: pos.remolque,
        numero_ejes: pos.numero_ejes,
        peso_vacio: pos.peso_vacio,
        numero_orden: pos.numero_orden,
        link_orden: pos.link_orden,
        sitio_destino: pos.sitio_destino,
        destino_tercero: pos.destino_tercero,
        transportadora_id: pos.transportadora_id,
        conductor_id: pos.conductor_id,
        conductor: pos.conductor,
        doc_conductor: pos.doc_conductor,
        cel_cond: pos.cel_cond,
        nom_conductor: pos.nom_conductor,
        fecha_cargue: pos.fecha_cargue,
        fecha: moment(pos.fecha_cargue).format("YYYY-MM-DD"),
        hora_cargue: moment(pos.fecha_cargue).format("HH:mm"),
        estado: pos.estado,
      };
    },

    cancelForm() {
      this.det_oferta = null;
      this.postu = null;
      this.resetForm();
    },

    modalFotoOrden(post) {
      this.post.llenar_modal_foto_orden(post);
    },

    elegirDirectorio() {
      let vm = this;
      vm.file = vm.$refs.file[0].files[0];
    },

    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    eliminarFoto() {
      this.file = null;
      this.form.link_orden = null;
    },

    calcSaldos() {
      let saldo = (this.oferta.cliente.saldos = this.oferta.cliente.saldo);

      let montoAnti = 0;
      this.oferta.cliente.com_anticipos.forEach((antic) => {
        if (antic.validado == 1) {
          montoAnti += parseFloat(antic.monto);
        }
      });

      let entregas = 0;
      let totalPrima = 0;

      this.oferta.guias.forEach((guia) => {
        if (guia.brent == null || guia.brent == "") {
          guia.brent = 0;
        }

        if (guia.precio_oferta == null || guia.precio_oferta == "") {
          guia.precio_oferta = 0;
        }

        let primaImp = 0;

        if (
          this.oferta.cliente_id == 22 ||
          this.oferta.cliente_id == 25 ||
          this.oferta.cliente_id == 8
        ) {
          if (guia.fecha_exp > "2024-01-31") {
            primaImp =
              parseFloat(guia.brent) +
              parseFloat(guia.precio_oferta) -
              (parseFloat(guia.brent) + parseFloat(guia.precio_oferta)) * 0.03;
          } else {
            guia.primaImp =
              parseFloat(guia.brent) +
              parseFloat(guia.precio_oferta) -
              (parseFloat(guia.brent) + parseFloat(guia.precio_oferta)) * 0.054;
          }
        } else {
          primaImp = parseFloat(guia.brent) + parseFloat(guia.precio_oferta);
        }

        totalPrima = parseFloat(primaImp) * parseFloat(guia.tot_nsv);
        entregas += parseFloat(totalPrima);
      });

      if (this.oferta.cliente_id == 4) {
        this.saldoFinal =
          parseFloat(saldo) + parseFloat(montoAnti) - parseFloat(entregas);
      } else {
        if (entregas > 0) {
          this.saldoFinal =
            parseFloat(saldo) +
            parseFloat(montoAnti) -
            parseFloat(entregas) -
            (parseFloat(saldo) + parseFloat(montoAnti) - parseFloat(entregas)) *
              0.054;
        } else {
          this.saldoFinal = parseFloat(saldo) + parseFloat(montoAnti);
        }
      }

      let saldoMasAnti = parseFloat(montoAnti) + parseFloat(saldo);

      this.oferta.cliente.porcentaje =
        100 -
        parseInt(
          (parseFloat(this.saldoFinal) /
            (parseFloat(saldo) + parseFloat(montoAnti))) *
            100
        );

      this.oferta.cliente.saldos = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        parseFloat(saldo).toFixed()
      );

      this.oferta.cliente.anticipos = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        parseFloat(montoAnti).toFixed()
      );

      this.oferta.cliente.saldoMasAnti = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        parseFloat(saldoMasAnti).toFixed()
      );

      this.oferta.cliente.saldo_final = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        parseFloat(this.saldoFinal).toFixed()
      );

      this.oferta.cliente.entregas = this.formatCurrency(
        "es-CO",
        "COP",
        2,
        parseFloat(entregas).toFixed()
      );
    },

    async buscarVehiculos(post, postulacion_id = null) {
      this.cargando = true;

      try {
        const response = await axios.get(
          "api/com/postulaciones/validaVehiculo",
          {
            params: {
              vehiculo: this.form.vehiculo,
              fecha: this.form.fecha,
            },
          }
        );

        if (response.data.postulado) {
          this.form.vehiculo = null;
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "El vehículo ya se encuentra postulado para esta fecha",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }

        const me = this;
        const vehiculoResponse = await axios.get("api/admin/vehiculos/lista", {
          params: {
            full_placa: this.form.vehiculo,
            in_tipo_vehiculo_id: [1, 6], // Se buscan [Cabezotes, Dobletroques]
          },
        });

        this.cargando = false;
        this.form.vehiculo_id =
          vehiculoResponse.data.length > 0 ? vehiculoResponse.data[0].id : null;
      } catch (error) {
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: "Ocurrió un error: " + error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    async listasClientes() {
      let me = this;
      var url = "api/com/clientes/listaDestino";
      axios
        .get(url, {
          params: {
            cliente_id: me.oferta.cliente_id,
          },
        })
        .then(function(response) {
          me.listasForms.destinosCliente = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    async buscarRemolques(post, postulacion_id = null) {
      this.cargando = false;
      /*  this.falgValidando = true;
      let dato = this.form.remolque;
      let res = await this.validarStatus(dato, "R");

      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.falgValidando = false;
          await this.msgDocumentacion(res);

          this.form.remolque = null;
          return false;
        }
      } */

      // Se valida si el vehículo del formulario ya exite en las postulaciones del día
      const filtro_repetido = postulacion_id
        ? post.find(
            (p) => p.remolque === this.form.remolque && p.id !== postulacion_id
          )
        : post.find((p) => p.remolque === this.form.remolque);

      if (!filtro_repetido) {
        // Se valida si la placa se encuentra registrada en Pegaso
        const me = this;
        axios
          .get("api/admin/remolques/lista", {
            params: {
              full_placa: this.form.remolque,
            },
          })
          .then(function(response) {
            me.cargando = false;
            me.form.remolque_id =
              response.data.length > 0 ? response.data[0].id : null;
            me.form.numero_ejes =
              response.data.length > 0 ? response.data[0].numero_ejes : null;
          })
          .catch(function(error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        return false;
      }

      // Si ya existe se anula el valor consignado en el formulario
      this.cargando = false;
      this.form.remolque = null;
      this.$swal({
        icon: "error",
        title: `El remolque ya se encuentra postulado para esta fecha`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    },

    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.transportadoras = response.data;
      });
    },

    selectempresa() {
      this.form.transportadora_id = null;
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    selectDestino() {
      this.form.sitio_destino = null;
      this.form.destino_tercero = null;

      if (this.sitios_destino) {
        this.form.sitio_destino = this.sitios_destino.nombre;
        this.form.destino_tercero = this.sitios_destino.numeracion;
      }
    },

    valEjes() {
      if (
        this.form.numero_ejes &&
        (this.form.numero_ejes < 2 || this.form.numero_ejes > 3)
      ) {
        this.form.numero_ejes = null;
        this.$swal({
          icon: "error",
          title: `Número de ejes incorrecto, por favor validar.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    valPesoVacio() {
      if (
        this.form.peso_vacio &&
        (this.form.peso_vacio < 10000 || this.form.peso_vacio > 30000)
      ) {
        this.form.peso_vacio = null;
        this.$swal({
          icon: "error",
          title: `Peso incorrecto, por favor validar.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async buscarConductores(post, postulacion_id = null) {
      this.cargando = false;
      /*      this.falgValidando = true;
      let dato = this.form.doc_conductor;
      let res = await this.validarStatus(dato, "C");

      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.falgValidando = false;
          await this.msgDocumentacion(res);
 
          this.form.doc_conductor = null;*/
      this.form.conductor_id = null;
      this.form.nom_conductor = null;
      this.form.cel_cond = null;
      /*        return false;
        }
      } */

      // Se valida si el vehículo del formulario ya exite en las postulaciones del día
      const filtro_repetido = postulacion_id
        ? post.find(
            (p) =>
              p.doc_conductor === this.form.doc_conductor &&
              p.id !== postulacion_id
          )
        : post.find((p) => p.doc_conductor === this.form.doc_conductor);

      if (!filtro_repetido) {
        // Se valida si la placa se encuentra registrada en Pegaso
        const me = this;
        axios
          .get("api/admin/conductores/lista", {
            params: {
              full_numero_documento: this.form.doc_conductor,
            },
          })
          .then(function(response) {
            me.cargando = false;
            me.form.conductor_id =
              response.data.length > 0 ? response.data[0].id : null;
            me.form.nom_conductor =
              response.data.length > 0 ? response.data[0].nombre : null;
            me.form.cel_cond =
              response.data.length > 0
                ? response.data[0].celular_principal
                : null;
          })
          .catch(function(error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        return false;
      }

      // Si ya existe se anula el valor consignado en el formulario
      this.cargando = false;
      this.form.doc_conductor = null;
      this.form.nom_conductor = null;
      this.form.cel_cond = null;
      this.$swal({
        icon: "error",
        title: `El conductor ya se encuentra postulado para esta fecha`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      return false;
    },

    abrirModalFechas(pos) {
      this.$refs.PostulacionCambioFecha.cargarDatos(pos);
    },

    /*     async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/com/postulaciones/listaConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/com/postulaciones/listaRemolques";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/com/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function (e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    }, 
    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos !== undefined) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.obligatorios.join("<br>");
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.no_verificados.join("<br>");
          }
          erroHtml += "</div>";
          await this.$swal({
            html: erroHtml,
            showCancelButton: false,
            focusConfirm: true,
            icon: "error",
            title: "Aviso en documentacion",
            showConfirmButton: false,
            showCloseButton: true,
            footer:
              "<small style='text-align:center'><b>Desde el 1 de abril se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
          });
        } else {
          this.$swal({
            icon: "error",
            title: res.validacion.error.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },*/

    /***** Funciones para los botones de los formularios */
    async save() {
      this.cargando = true;
      const method = this.form.id ? "PUT" : "POST";

      let formData = new FormData();

      formData.append("id", this.form.id ? this.form.id : null);
      formData.append(
        "com_oferta_subasta_det_id",
        this.form.com_oferta_subasta_det_id
      );
      formData.append(
        "cliente_id",
        this.oferta ? this.oferta.cliente_id : null
      );
      formData.append(
        "vehiculo_id",
        this.form.vehiculo_id ? this.form.vehiculo_id : null
      );
      formData.append(
        "vehiculo",
        this.form.vehiculo ? this.form.vehiculo : null
      );
      formData.append(
        "remolque_id",
        this.form.remolque_id ? this.form.remolque_id : null
      );
      formData.append(
        "remolque",
        this.form.remolque ? this.form.remolque : null
      );
      formData.append(
        "numero_orden",
        this.form.numero_orden ? this.form.numero_orden : null
      );
      formData.append(
        "numero_ejes",
        this.form.numero_ejes ? this.form.numero_ejes : null
      );
      formData.append(
        "peso_vacio",
        this.form.peso_vacio ? this.form.peso_vacio : null
      );
      formData.append(
        "sitio_destino",
        this.form.sitio_destino ? this.form.sitio_destino : null
      );
      formData.append(
        "destino_tercero",
        this.form.destino_tercero ? this.form.destino_tercero : null
      );
      formData.append(
        "transportadora_id",
        this.form.transportadora_id ? this.form.transportadora_id : null
      );
      formData.append(
        "conductor_id",
        this.form.conductor_id ? this.form.conductor_id : null
      );
      formData.append(
        "cel_cond",
        this.form.cel_cond ? this.form.cel_cond : null
      );
      formData.append(
        "conductor",
        `${this.form.doc_conductor} ${this.form.nom_conductor}`
          ? `${this.form.doc_conductor} ${this.form.nom_conductor}`
          : null
      );
      formData.append(
        "fecha_cargue",
        `${this.form.fecha} ${this.form.hora_cargue}`
      );
      formData.append("estado", 1);
      formData.append("link_orden", this.file);
      axios({
        method: "POST",
        url: "/api/com/postulaciones",
        data: formData,
      })
        .then(() => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Los datos de la postulación se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cancelForm();
          this.getIndex();
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar esta postulación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          await axios
            .delete("/api/com/postulaciones/" + id)
            .then(() => {
              this.cargando = false;
              this.cancelForm();
              this.getIndex();
              this.$swal({
                icon: "success",
                title:
                  "Se eliminó el detalle de la postulación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    back() {
      return this.$router.replace("/Com/Postulaciones");
    },
  },

  mounted() {
    this.getIndex();
    this.getEmpresas();
  },
};
</script>
